import React from 'react';
import styled, { css } from 'styled-components';

import { FontColor } from '../../interfaces';
import { shadow } from '../../libs/styled/mixin';

export type TypographyStyleProps = {
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  color?: 'initial' | FontColor;
  display?: 'initial' | 'block' | 'inline';
  noWrap?: boolean;
  gutterBottom?: boolean;
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'p'
    | 'largeTitle'
    | 'description'
    | 'title'
    | 'label';
  fontType?: 'noto' | 'mincho';
};

const fontTypes = {
  noto: `'Noto Sans JP', sans-serif`,
  mincho: `'游明朝体', 'Yu Mincho', YuMincho, 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', 'MS P明朝', 'MS PMincho', 'Noto Serif JP', serif`,
};

const propsToStyle = ({
  color = 'white',
  align = 'inherit',
  display = 'initial',
  variant = 'p',
  fontType = 'noto',
  gutterBottom = false,
}: TypographyStyleProps) => {
  const useGutterBottom = gutterBottom || variant !== 'label';
  const bottomStyle = useGutterBottom && {
    marginBottom: '0.35em',
  };

  const fontFamily = fontTypes[fontType];

  return css`
    ${css`
      ${{
        textAlign: align,
        color,
        display,
        fontFamily,
        ...bottomStyle,
      }}
    `}
  `;
};

const H1 = styled.h1<TypographyStyleProps>`
  font-size: 3.75rem;
  font-weight: 700;
  line-height: 1.167;
  letter-spacing: -0.01562em;
  margin: 0;

  ${(props) => propsToStyle(props)};
`;

const H2 = styled.h2<TypographyStyleProps>`
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -0.00833em;
  margin: 0;

  ${(props) => propsToStyle(props)};
`;

const H3 = styled.h3<TypographyStyleProps>`
  font-size: 2.125rem;
  font-weight: 700;
  line-height: 1.167;
  letter-spacing: 0em;
  margin: 0;

  ${(props) => propsToStyle(props)};
`;

const H4 = styled.h4<TypographyStyleProps>`
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.235;
  letter-spacing: 0.00735em;
  margin: 0;

  ${(props) => propsToStyle(props)};
`;

const H5 = styled.h5<TypographyStyleProps>`
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.334;
  letter-spacing: 0em;
  margin: 0;

  ${(props) => propsToStyle(props)};
`;

const H6 = styled.h6<TypographyStyleProps>`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  margin: 0;

  ${(props) => propsToStyle(props)};
`;

const P = styled.p<TypographyStyleProps>`
  font-size: 0.9rem;
  margin: 0;
  word-break: break-word;

  ${(props) => propsToStyle(props)};
`;

const LargeTitle = styled.p<TypographyStyleProps>`
  font-size: 1rem;
  font-weight: 700;
  margin: 0;

  ${(props) => propsToStyle(props)};
`;

const Title = styled.p<TypographyStyleProps>`
  font-size: 0.8rem;
  font-weight: 700;
  margin: 0;

  ${(props) => propsToStyle(props)};
`;

const Description = styled.p<TypographyStyleProps>`
  font-size: 0.8rem;
  margin: 0;

  ${(props) => propsToStyle(props)};
`;

const Label = styled.label<TypographyStyleProps>`
  font-size: 0.7rem;
  line-height: 1;
  word-break: break-all;

  ${(props) => propsToStyle(props)};
`;

const variantMap = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  p: P,
  largeTitle: LargeTitle,
  description: Description,
  title: Title,
  label: Label,
} as {
  [key: string]: any;
};

type TypographyProps = TypographyStyleProps & {
  className?: string;
};

const Typography: React.FC<TypographyProps> = ({
  className,
  variant = 'p',
  ...other
}) => {
  const Component = variantMap[variant];
  return <Component className={className} variant={variant} {...other} />;
};

const ShadowedTypography = styled(Typography)`
  ${shadow('text')}
`;

export default Typography;
export { ShadowedTypography };
