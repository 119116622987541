import React from 'react';

type BoxProps = {
  children?: any;
  className?: string;
  container?: boolean;
  flexDirection?: 'row' | 'column';
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'initial'
    | 'inherit';
  flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
  alignItems?:
    | 'stretch'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'baseline'
    | 'initial'
    | 'inherit';

  flexGrow?: number;
  flexShrink?: number;
  flexBasis?: number;
  flex?: string;

  padding?: string;
  margin?: string;
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  onClick?: () => void;
};

const Box: React.FC<BoxProps> = ({
  className,
  container,
  justifyContent = 'flex-start',
  flexDirection = 'row',
  flexGrow = 0,
  flexBasis = 'auto',
  flexShrink = 1,
  flexWrap = 'nowrap',
  flex = '0 1 auto',
  alignItems = 'stretch',
  margin = '0',
  padding = '0',
  width = 'auto',
  height = 'auto',
  maxWidth = 'none',
  children,
  onClick,
}) => {
  return (
    <div
      className={className}
      onClick={onClick}
      style={{
        display: container ? 'flex' : 'block',
        justifyContent: justifyContent,
        flexDirection: flexDirection,
        flexGrow: flexGrow,
        flexBasis: flexBasis,
        flexShrink: flexShrink,
        flexWrap: flexWrap,
        flex: flex,
        alignItems: alignItems,
        margin: margin,
        padding: padding,
        width: width,
        height: height,
        maxWidth: maxWidth,
      }}
    >
      {children}
    </div>
  );
};

const HBox: React.FC<BoxProps> = (props) => {
  return (
    <Box container={true} flexDirection="row" alignItems="center" {...props} />
  );
};

const VBox: React.FC<BoxProps> = (props) => {
  return (
    <Box
      container={true}
      flexDirection="column"
      justifyContent="center"
      {...props}
    />
  );
};

export default Box;
export { HBox, VBox };
