import { css, FlattenSimpleInterpolation } from 'styled-components';

import { color } from './theme';

const config = {
  transition: '0.3s',
  shadowSize: 2,
};

const hover = (): FlattenSimpleInterpolation => {
  return css`
    cursor: pointer;
    transition: ${config.transition};

    &:hover {
      opacity: 0.7;
    }
  `;
};

const defaultColors = {
  default: color.white,
  hover: color.gold,
};

const coloredHover = (
  target = 'color',
  colors = defaultColors,
): FlattenSimpleInterpolation => {
  return css`
    ${{
      'user-select': 'none',
      cursor: 'pointer',
      transition: config.transition,
      [target]: colors.default,

      '&:hover': {
        [target]: colors.hover,
      },
    }}
  `;
};

const linearGradient = (): FlattenSimpleInterpolation => {
  return css`
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 5%,
      rgba(0, 0, 0, 0) 95%,
      rgba(0, 0, 0, 0.5) 100%
    );
  `;
};

const shadow = (type: 'text' | 'svg'): FlattenSimpleInterpolation => {
  if (type === 'text') {
    return css`
      text-shadow: 0px ${config.shadowSize}px ${config.shadowSize}px
        rgba(0, 0, 0, 0.6);
    `;
  }

  return css`
    filter: drop-shadow(
      0px ${config.shadowSize}px ${config.shadowSize}px rgba(0, 0, 0, 0.6)
    );
  `;
};

export { coloredHover, config, hover, linearGradient, shadow };
