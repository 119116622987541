import { NextPage } from 'next';
import React from 'react';

import { VBox } from '../components/common/Box';
import Typography from '../components/common/Typography';

const PageNotFound: NextPage = () => {
  return (
    <VBox
      height="100vh"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h5">404 - Page Not Found</Typography>
    </VBox>
  );
};

export default PageNotFound;
